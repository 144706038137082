import React from 'react'
import { CtaBar, LeshenPhoneCTA } from '@leshen/gatsby-theme-leshen'

const SeoCtaBar = () => {
  return (
    <CtaBar
      backgroundColor="secondary"
      sticky
      leftBlock={
        <LeshenPhoneCTA mobileText="Call" color="white">
          Call for Short Wait Times
        </LeshenPhoneCTA>
      }
    />
  )
}

export default SeoCtaBar
